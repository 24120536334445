import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Navbar, Nav, NavDropdown, Image } from 'react-bootstrap';
import './header.css';
import 'bootstrap/dist/css/bootstrap.css';
import './global.css';
import logoImg from './assets/logoPop.png';


export default function Header() {
  const { t } = useTranslation();

  const scrollToTop = () => {
    window.scrollTo(0, 0);
  };

  return (
    <Navbar
      collapseOnSelect
      expand="lg"
      bg="navColor"
      variant="light"
      fixed="top"
      sm="true"
      style={{ minWidth: '400px' }}
      className="navbar navbar-custom "
    >
      <Navbar.Brand style={{ display: 'none' }} className="mainLogo1">
        <Link to="/">
          <Image id="popLogo" src={logoImg} />
        </Link>
      </Navbar.Brand>

      <Navbar.Toggle aria-controls="-navbar-nav" className="navbar-toggle order-md-1 order-0" />

      <Navbar.Collapse id="barItens" className="justify-content-center">
        <Navbar.Brand className="mainLogo">
          <Link to="/">
            <Image id="popLogo" src={logoImg} />
          </Link>
        </Navbar.Brand>

        <Nav className="navMain">
          <Nav.Link className="navItens" href="/#/" onClick={scrollToTop}>
            PoP-PE
          </Nav.Link>

          {/* <NavDropdown renderMenuOnMount className="navItens" title={t('home.sobre.menu')} href="#template">
            <NavDropdown.Item id="nav-dropdown" href="/#/Historia">
              {t('home.sobre.pop-pe')}
            </NavDropdown.Item>
            <NavDropdown.Item id="nav-dropdown" href="/#/Equipe">
              {t('home.sobre.equipe')}
            </NavDropdown.Item>
          </NavDropdown> */}

          <Nav.Link className="navItens" href="/#/Equipe" onClick={scrollToTop}>
            Equipe
          </Nav.Link>
          <Nav.Link className="navItens" href="/#/Clientes" onClick={scrollToTop}>
            {t('home.clientes')}
          </Nav.Link>

          <NavDropdown renderMenuOnMount className="navItens" title={'Redes'} id="collasible-nav-dropdown">
            <NavDropdown.Item id="nav-dropdown" href="/#/Redecomep_Recife" onClick={scrollToTop}>
              {t('Redecomep de Recife')}
            </NavDropdown.Item>
            <NavDropdown.Item id="nav-dropdown" href="/#/redevasf" onClick={scrollToTop}>
              {t('RedeVASF')}
            </NavDropdown.Item>
            <NavDropdown.Item id="nav-dropdown" href="/#/REPEPE" onClick={scrollToTop}>
              {t('RePEPE')}
            </NavDropdown.Item>
            <NavDropdown.Item id="nav-dropdown" href="/#/veredas" onClick={scrollToTop}>
              {t('Veredas')}
            </NavDropdown.Item>
            <NavDropdown.Item id="nav-dropdown" href="/#/ixbr" onClick={scrollToTop}>
              {t('IX.br')}
            </NavDropdown.Item>
          </NavDropdown>

          {/* <NavDropdown
            renderMenuOnMount={true}
            className="navItens"
            title="Panoramas"
            id="collasible-nav-dropdown"
          >
            <NavDropdown.Item id="nav-dropdown" href="/#/Rede_metropolitana">
              {t("home.panorama.redemetropolitana")}
            </NavDropdown.Item>
            <NavDropdown.Item id="nav-dropdown" href="/#/Backbone_rnp">
              {t("home.panorama.backbone_rnp")}
            </NavDropdown.Item>
            <NavDropdown.Item id="nav-dropdown" href="/#/Nicbr">
              {t("home.panorama.nicbr")}
            </NavDropdown.Item>
          </NavDropdown>
          <NavDropdown
            renderMenuOnMount={true}
            className="navItens"
            title="Notícias &amp; Eventos"
            id="collasible-nav-dropdown"
          >
            <NavDropdown.Item id="nav-dropdown" href="/#/Noticias">
              Notícias{" "}
            </NavDropdown.Item>
            <NavDropdown.Divider />
            <NavDropdown.Item id="nav-dropdown" href="/#/Eventos">
              WTR 2019{" "}
            </NavDropdown.Item>
          </NavDropdown> */}

          <Nav.Link id="contactBtn" className="navItens contactNav" href="/#/Contact" onClick={scrollToTop}>
            {t('contact.menu')}
          </Nav.Link>
        </Nav>

        {/* <Link to={location.pathname} className="img-languages">
          <Image
            src={brFlagImg}
            className="img-link"
            title="Português do Brasil"
            rounded
            width="25px"
            onClick={() => changeLanguage('pt')}
          />

          <Image
            src={usFlagImg}
            className="img-link"
            title="English"
            rounded
            width="25px"
            onClick={() => changeLanguage('en')}
          />
        </Link> */}
      </Navbar.Collapse>
    </Navbar>
  );
}
