import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles.css';
import Loader from '../../../loader.js';
import Header from '../../../header.js';
import FooterMenu from '../../../FooterMenu.js';
import Footer from '../../../footer.js';
import './../../../global.css';
import '../../breadcrumb.css';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  header: {
    padding: useTheme().spacing(4.6),
    textAlign: 'center',
    color: useTheme().palette.text.secondary,
    backgroundColor: 'white',
  },
  title: {
    padding: useTheme().spacing(5),
    textAlign: 'left',
    color: useTheme().palette.text.secondary,
    backgroundColor: '#135EA7',
  },
  breadcrumb: {
    padding: useTheme().spacing(5),
    textAlign: 'right',
    color: useTheme().palette.text.secondary,
    backgroundColor: '#135EA7',
  },
  body: {
    padding: useTheme().spacing(5),
    textAlign: 'justify',
    backgroundColor: 'white',
  },
}));

function Page() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper className={classes.header} square>
            <Header />
          </Paper>
        </Grid>

        <Grid item xs={12} className="subHeader">
          <Container>
            <Row id="crumbsMenu" className="justify-content-md-start">
              <Col xs lg="5" className={classes.title} id="tituloSubHeader" sm={6}>
                {t('IX.br')}
              </Col>
            </Row>
          </Container>
        </Grid>

        <Grid item xs={12} style={{ backgroundColor: '#fff' }}>
          <Container>
            <Row className="justify-content-md-center">
              <Col id="texto-recursos" xs lg="10" className={classes.body}>
                <p className="infraP">
                  O <span id="spotName">IX.br</span> é o nome dado ao projeto do Comitê Gestor da Internet no Brasil
                  (CGIbr) que promove e cria a infraestrutura necessária para a interconexão direta entre as redes
                  ("Autonomous Systems" - ASs) que compõem a Internet Brasileira. A atuação do{' '}
                  <span id="spotName">IX.br</span> volta-se às regiões metropolitanas no País que apresentam grande
                  interesse de troca de tráfego Internet. Em Recife, o <span id="spotName">IX.br</span> está fisicamente
                  localizado no PoP-PE/RNP, possuindo as seguintes vantagens:
                  <ul id="listsN">
                    <li id="listsL">
                      Racionalização dos custos, uma vez que os balanços de tráfego são resolvidos direta e localmente e
                      não através de redes de terceiros, muitas vezes fisicamente distantes.
                    </li>
                    <li id="listsL">
                      Maior controle que uma rede pode ter com relação a entrega de seu tráfego o mais próximo possível
                      do seu destino, o que em geral resulta em melhor desempenho e qualidade para seus clientes e
                      operação mais eficiente da Internet como um todo.
                    </li>
                  </ul>
                  {/* <img id="mapaIxbr" className="infraImg" src={ixbr} alt="Mapa Ixbr" /> */}

                  <img id="mapaIxbr" className="infraImg" src='https://old.ix.br/stats/23a4632eb97d8c22dafbeccc1706723f/pe/images/setas01.png' alt="Mapa Ixbr" />

                  
                  <p id="netRef">
                    <p className="cardItem">
                      Fontes:
                      <a className="linkContact" target="_blank" rel="noopener noreferrer" href="https://ix.br/sobre">
                        <b className="linkRef"> https://ix.br/sobre</b>
                      </a>
                      <a> |</a>
                      <a
                        className="linkContact"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://ix.br/adesao/pe/"
                      >
                        <b className="linkRef"> https://ix.br/adesao/pe/</b>
                      </a>
                    </p>
                  </p>
                </p>
                <br />
                <br />
              </Col>
            </Row>
          </Container>
        </Grid>
        <Grid item xs={12} className="footer-menu-background">
          <FooterMenu />
        </Grid>

        <Grid item xs={12} className="footer-background">
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
      {/* <Loader /> */}
    </Suspense>
  );
}
