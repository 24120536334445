import React, { useEffect, useState } from "react";
import "../styles.css";

function Mapa() {
  const [zoom, setZoom] = useState(8);
  const [topPosition, setTopPosition] = useState("-60px");

  useEffect(() => {
    const handleResize = () => {
      const windowWidth = window.innerWidth;
      let newZoom;
      let newTopPosition;

      if (windowWidth < 768) {
        newZoom = 6;
        newTopPosition = "-150px"; 
      } else if (windowWidth < 1400) {
        newZoom = 7;
        newTopPosition = "-60px"; 
      } else {
        newZoom = 8;
        newTopPosition = "-60px";
      }

      setZoom(newZoom);
      setTopPosition(newTopPosition);
    };

    window.addEventListener("resize", handleResize);
    handleResize();

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const mapSrc = `https://www.google.com/maps/d/embed?mid=1pgi8FwhZLFICKI0023Eff-dBbgRPlsY&ehbc=2E312F&z=${zoom}`;

  return (
    <div className="maps">
      <iframe
        className="custom-google-map"
        style={{ position: "relative", top: topPosition, border: "none" }}
        src={mapSrc}
        width="100%"
        height="520"
        title="Google Map"
      />
    </div>
  );
}

export default Mapa;
