import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import { useTheme } from '@mui/material/styles';
import { makeStyles } from '@mui/styles';
import 'bootstrap/dist/css/bootstrap.min.css';
import React, { Suspense } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { FaLinkedin } from 'react-icons/fa';
import FooterMenu from '../../FooterMenu.js';
import Footer from '../../footer.js';
import Header from '../../header.js';
import Loader from '../../loader.js';
import './../breadcrumb.css';
import gesianny from './fotos/gesianny.jpg';
import jefferson from './fotos/jefferson.jpg';
import joao from './fotos/joao.jpg';
import miguel from './fotos/miguel.jpg';
import rafael from './fotos/rafael.jpg';
import reidylla from './fotos/reidylla.jpg';
import rodrigo from './fotos/rodrigo.jpg';
import walter from './fotos/walter.jpg';
import zuleika from './fotos/zuleika.jpg';
import './styles.css';


const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 2,
  },
  header: {
    padding: useTheme().spacing(4.6),
    textAlign: 'center',
    color: useTheme().palette.text.secondary,
    backgroundColor: 'white',
  },
  title: {
    padding: useTheme().spacing(5),
    textAlign: 'left',
    color: useTheme().palette.text.secondary,
    backgroundColor: '#000A8C',
  },
  breadcrumb: {
    padding: useTheme().spacing(5),
    textAlign: 'right',
    color: useTheme().palette.text.secondary,
    backgroundColor: '#000A8C',
  },
  body: {
    padding: useTheme().spacing(5),
    textAlign: 'left',
    color: useTheme().palette.text.secondary,
    backgroundColor: 'white',
  },
}));

function Page() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper className={classes.header} square>
            <Header />
          </Paper>
        </Grid>

        <Grid item xs={12} className='subHeader'>
          <Container>
            <Row id='crumbsMenu' className="justify-content-md-start">
              <Col xs lg={5} className={classes.title} id="tituloSubHeader" sm={6}>
                {t('Equipe')}
              </Col>
            </Row>
          </Container>
        </Grid>

        <Container style={{ padding: '30px', width: '100%' }}>
          <Row style={{ width: 'auto', display: 'flex' }}>
            <div className="containerTeam">
              {/* Pináculo Ordem Crescente */}

              <div className="card">
                <div className="content">
                  <div className="imgBx">
                    <img className="teamPic" src={zuleika} alt="" />
                  </div>
                  <div className="contentBx">
                    <h4>Zuleika Tenório</h4>
                    <h5>Coordenadora</h5>
                  </div>
                  <div className="sci">
                    <a href="/#/Equipe">
                      <FaLinkedin />
                    </a>
                  </div>
                </div>
              </div>

              {/* Internos Ordem crescente */}

              <div className="card">
                <div className="content">
                  <div className="imgBx">
                    <img className="teamPic" src={gesianny} alt="" />
                  </div>
                  <div className="contentBx">
                    <h4>Gesianny Azevedo</h4>
                    <h5>Suporte Administrativo</h5>
                  </div>
                  <div className="sci">
                    <a
                      href="https://www.linkedin.com/in/gesianny-crispim-de-azevedo-10308a17a"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="content">
                  <div className="imgBx">
                    <img className="teamPic" src={joao} alt="" />
                  </div>
                  <div className="contentBx">
                    <h4>João Luiz</h4>
                    <h5>Analista de Operações Pl.</h5>
                  </div>
                  <div className="sci">
                    <a href="https://www.linkedin.com/in/joaoluizramos96/" target="_blank" rel="noopener noreferrer">
                      <FaLinkedin />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="content">
                  <div className="imgBx">
                    <img className="teamPic" src={jefferson} alt="" />
                  </div>
                  <div className="contentBx">
                    <h4>Jefferson Clebson</h4>
                    <h5>Analista DevOps Jr.</h5>
                  </div>
                  <div className="sci">
                    <a href="/#/Equipe">
                      <FaLinkedin />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="content">
                  <div className="imgBx">
                    <img className="teamPic" src={miguel} alt="" />
                  </div>
                  <div className="contentBx">
                    <h4>Miguel Lima</h4>
                    <h5>Estagiário</h5>
                  </div>
                  <div className="sci">
                    <a href="/#/Equipe">
                      <FaLinkedin />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="content">
                  <div className="imgBx">
                    <img className="teamPic" src={rafael} alt="" />
                  </div>
                  <div className="contentBx">
                    <h4>Rafael Leal</h4>
                    <h5>Analista DevOps Jr.</h5>
                  </div>
                  <div className="sci">
                    <a
                      href="https://www.linkedin.com/in/rafael-leal-guimar%C3%A3es-147157172/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="content">
                  <div className="imgBx">
                    <img className="teamPic" src={rodrigo} alt="" />
                  </div>
                  <div className="contentBx">
                    <h4>Rodrigo Brayner</h4>
                    <h5>Consultor Devops</h5>
                  </div>
                  <div className="sci">
                    <a
                      href="https://www.linkedin.com/in/rodrigo-brayner-73a5444/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="content">
                  <div className="imgBx">
                    <img className="teamPic" src={reidylla} alt="" />
                  </div>
                  <div className="contentBx">
                    <h4>Reidylla Lima</h4>
                    <h5>Analista de Operações Pl.</h5>
                  </div>
                  <div className="sci">
                    <a
                      href="https://www.linkedin.com/in/reidylla-lima-ba2040144/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin />
                    </a>
                  </div>
                </div>
              </div>

              <div className="card">
                <div className="content">
                  <div className="imgBx">
                    <img className="teamPic" src={walter} alt="" />
                  </div>
                  <div className="contentBx">
                    <h4>Walter Alves</h4>
                    <h5>Estagiário</h5>
                  </div>
                  <div className="sci">
                  <a
                      href="https://www.linkedin.com/in/walter-alves-dos-santos-filho-465a29198/"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <FaLinkedin />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </Row>
        </Container>

        <Grid item xs={12} className="footer-menu-background">
          <FooterMenu />
        </Grid>

        <Grid item xs={12} className="footer-background">
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
      {/* <Loader /> */}
    </Suspense>
  );
}
