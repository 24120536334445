import React from 'react';
import { HashRouter as Router, Route, Routes } from 'react-router-dom';

import Home from './pages/Home';
import Contact from './pages/Contact';
import RedeVasf from './pages/Infraestrutura/redevasf';
import Redecomep_Recife from './pages/Infraestrutura/redecomepe_recife';
import RePEPE from './pages/Infraestrutura/REPEPE';
import Veredas from './pages/Infraestrutura/veredas';
import IXbr from './pages/Infraestrutura/IXbr';
import Clientes from './pages/Clientes';
import Equipe from './pages/Equipe';

export default function Routesr() {
  return (
    <Router>
      <Routes>
        <Route path="/" exact element={<Home />} />
        <Route path="/Contact" element={<Contact />} />
        <Route path="/RedeVasf" element={<RedeVasf />} />
        <Route path="/Redecomep_Recife" element={<Redecomep_Recife />} />
        <Route path="/RePEPE" element={<RePEPE />} />
        <Route path="/Veredas" element={<Veredas />} />
        <Route path="/IXbr" element={<IXbr />} />
        <Route path="/Clientes" element={<Clientes />} />
        <Route path="/Equipe" element={<Equipe />} />
      </Routes>
    </Router>
  );
}
