import React from 'react';
import './footer.css';
import brandFooter from './assets/POP_PE_PRORNP_RGB_PNG.png';

export default function Footer() {
  return (
    <div className='government'>
      <div className='govContainer'>
        <div className="row justify-content-center text-center">
          <div className="col-10"> 
            <img src={brandFooter} className="img-fluid d-none d-sm-block"/>
            <img src={brandFooter} className="img-fluid mb-3 d-sm-none"></img>
          </div>
        </div>
      </div>
    </div>
  );
}
