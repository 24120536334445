import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import React from 'react';
import Slider from 'react-slick';

import './sliders.css';

import ifpe from '../Clientes/logos/1200px-Ifpe_logomarca.png';
import apac from '../Clientes/logos/APAC.png';
import cisam from '../Clientes/logos/cisam.png';
import ifsertao from '../Clientes/logos/IFsertao.png';
import ipa from '../Clientes/logos/IPA_logo.png';
import fundaj from '../Clientes/logos/logo_fundaj_2012.png';
import procape from '../Clientes/logos/procape.png';
import secti from '../Clientes/logos/secti_logo.png';
import ses from '../Clientes/logos/ses-pe.png';
import ufpe from '../Clientes/logos/UFPE_logo.png';
import ufrpe from '../Clientes/logos/ufrpe-logao.png';
import univasf from '../Clientes/logos/UNIVASF.png';
import upe from '../Clientes/logos/UPE logo.png';
import chesf from '../Clientes/logos/chesf.png';
import hcp from '../Clientes/logos/hcp.png';
import cmr from '../Clientes/logos/cmr.png';
import cpor from '../Clientes/logos/cpor.png';
import imip from '../Clientes/logos/imip.png';
import ipem from '../Clientes/logos/ipem.png';
import itep from '../Clientes/logos/itep.png';

const Sliders = () => {
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 6.7,
    autoplay: true,
    speed: 4850,
    autoplaySpeed: 0,
    cssEase: 'linear',
    rows: 1,
    centerPadding: '60px',
    responsive: [
      {
        breakpoint: 1750,
        settings: {
          slidesToShow: 6,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1550,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 1050,
        settings: {
          slidesToShow: 3.5,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 900,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 780,
        settings: {
          slidesToShow: 2.3,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1.6,
          slidesToScroll: 1.9,
        },
      },
    ],
  };

  return (
    <div className="wrapper">
      <h2 className="tittle">Nossos Clientes</h2>
      <Slider className="sliderBox" {...settings}>
        <div className="imgBox">
          <a href="https://www.ifpe.edu.br/" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="ifpe"
              src={ifpe}
              style={{
                width: '225px',
                maxWidth: '100%',
                height: 'auto',
                marginTop: '5px',
              }}
            />
          </a>
        </div>
        <div className="imgBox">
          <a href="https://www.apac.pe.gov.br/" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="apac"
              src={apac}
              style={{ maxWidth: '120%', height: 'auto', position: 'relative', top: '-5px', right: '20px' }}
            />
          </a>
        </div>
        <div className="imgBox">
          <a href="https://www.upe.br/uh-cisam.html" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="cisam"
              src={cisam}
              style={{
                width: '200px',
                maxWidth: '100%',
                height: 'auto',
                marginLeft: '12px',
                marginTop: '5px',
              }}
            />
          </a>
        </div>
        <div className="imgBox">
          <a href="https://www.ifsertao-pe.edu.br/" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="ifsertao"
              src={ifsertao}
              style={{
                width: '290px',
                maxWidth: '110%',
                height: 'auto',
                position: 'relative',
                top: '13px',
                right: '10px',
              }}
            />
          </a>
        </div>
        <div className="imgBox">
          <a href="https://www.ufpe.br/" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="ufpe"
              src={ufpe}
              style={{
                background: 'darkred',
                width: '85%',
                borderRadius: '4px',
                marginLeft: '17px',
                height: 'auto',
                marginTop: '5px',
              }}
            />
          </a>
        </div>
        <div className="imgBox">
          <a href="https://www.fundaj.gov.br/" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="fundaj"
              src={fundaj}
              style={{
                maxWidth: '113%',
                height: 'auto',
                position: 'relative',
                top: '28px',
                right: '14px',
              }}
            />
          </a>
        </div>
        <div className="imgBox">
          <a href="https://www.huprocape.pe.gov.br/" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="procape"
              src={procape}
              style={{
                maxWidth: '110%',
                height: 'auto',
                position: 'relative',
                top: '12px',
                right: '11px',
              }}
            />
          </a>
        </div>
        <div className="imgBox">
          <a href="https://www.secti.pe.gov.br/" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="secti"
              src={secti}
              style={{
                maxWidth: '113%',
                height: 'auto',
                position: 'relative',
                top: '23px',
                rigth: '10px',
                left: '-15px',
              }}
            />
          </a>
        </div>
        <div className="imgBox">
          <a href="https://www.saude.pe.gov.br/" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="ses"
              src={ses}
              style={{
                maxWidth: '80%',
                height: 'auto',
                position: 'relative',
                top: '-10px',
                rigth: '0',
                left: '17px',
              }}
            />
          </a>
        </div>
        <div className="imgBox">
          <a href="https://www.ipa.br/" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="ipa"
              src={ipa}
              style={{
                maxWidth: '62%',
                height: 'auto',
                position: 'relative',
                top: '-9px',
                rigth: '0',
                left: '36px',
              }}
            />
          </a>
        </div>
        <div className="imgBox">
          <a href="https://www.ufrpe.br/" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="ufrpe"
              src={ufrpe}
              style={{
                maxWidth: '45%',
                height: 'auto',
                position: 'relative',
                top: '-5px',
                rigth: '0',
                left: '50px',
              }}
            />
          </a>
        </div>
        <div className="imgBox">
          <a href="https://www.upe.br/" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="upe"
              src={upe}
              style={{ maxWidth: '90%', height: 'auto', position: 'relative', top: '-5px', rigth: '0', left: '10px' }}
            />
          </a>
        </div>
        <div className="imgBox">
          <a href="https://portais.univasf.edu.br/" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="univasf"
              src={univasf}
              style={{ maxWidth: '95%', height: 'auto', position: 'relative', top: '-8px', rigth: '0', left: '0px' }}
            />
          </a>
        </div>
        {/* middle */}
        <div className="imgBox">
          <a href="https://www.chesf.com.br/" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="chesf"
              src={chesf}
              style={{
                maxWidth: '120%',
                height: 'auto',
                position: 'relative',
                top: '-5px',
                rigth: '0px',
                left: '-20px',
              }}
            />
          </a>
        </div>
        <div className="imgBox">
          <a href="https://www.hcp.org.br/" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="hcp"
              src={hcp}
              style={{
                maxWidth: '100%',
                height: 'auto',
                position: 'relative',
                top: '0px',
                rigth: '0px',
                left: '0px',
              }}
            />
          </a>
        </div>
        <div className="imgBox">
          <a href="https://www.cmr.eb.mil.br/" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="cmr"
              src={cmr}
              style={{
                maxWidth: '48%',
                height: 'auto',
                position: 'relative',
                top: '-7px',
                rigth: '0px',
                left: '55px',
              }}
            />
          </a>
        </div>
        <div className="imgBox">
          <a href="https://www.cporr.eb.mil.br/" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="cpor"
              src={cpor}
              style={{
                maxWidth: '53%',
                height: 'auto',
                position: 'relative',
                top: '-7px',
                rigth: '0px',
                left: '45px',
              }}
            />
          </a>
        </div>
        <div className="imgBox">
          <a href="https://www.ipem.pe.gov.br/" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="ipem"
              src={ipem}
              style={{
                maxWidth: '96%',
                height: 'auto',
                position: 'relative',
                top: '0px',
                rigth: '0px',
                left: '5px',
              }}
            />
          </a>
        </div>
        <div className="imgBox">
          <a href="https://www1.imip.org.br/imip/home/index.html" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="imip"
              src={imip}
              style={{
                background: 'darkgreen',
                width: '100%',
                height: 'auto',
                position: 'relative',
                borderRadius: '3px',
                top: '18px',
                rigth: '0px',
                left: '0px',
              }}
            />
          </a>
        </div>
        <div className="imgBox">
          <a href="https://www.itep.br/" target="_blank" rel="noopener noreferrer">
            <img
              className="img-responsive"
              alt="itep"
              src={itep}
              style={{
                maxWidth: '135%',
                height: 'auto',
                position: 'relative',
                top: '-6px',
                rigth: '0px',
                left: '-17px',
              }}
            />
          </a>
        </div>
      </Slider>
    </div>
  );
};

export default Sliders;
