import React from 'react';
import Grid from '@mui/material/Grid';
import './styles.css';

function QuemSomos() {
  return (
    <Grid item xs={12}>
      <div id="quemSomos" square="true">
        <p id="tituloinfo">Quem Somos</p>
        <p id="quemSomosText">
          A Rede Nacional de Ensino e Pesquisa (RNP), é uma rede brasileira para educação e pesquisa. Uma plataforma de
          comunicação e colaboração digital que trabalha para promover e implementar a inovação em aplicações de
          tecnologia da informação. A RNP está presente em todas as capitais do Brasil, atuando em Pernambuco através do
          PoP-PE (Ponto de Presença em Pernambuco), fornecendo conectividade à Internet e serviços, direcionado ao
          atendimento de instituições acadêmicas de ensino superior e de pesquisa, ​​sendo assim fundamental para o
          desenvolvimento de projetos de pesquisa e aplicações inovadoras, contribuindo para o crescimento da ciência e
          tecnologia no Estado.
        </p>
      </div>
    </Grid>
  );
}

export default QuemSomos;
