import React from 'react';
import { Spinner } from 'react-bootstrap';

export default function Loader() {
  return (
    <div className="App" style={{ marginTop: '100px' }}>
      <Spinner animation="border" variant="info" />
      <div>Carregando...</div>
    </div >
  );
}

