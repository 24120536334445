import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles.css';
import Loader from '../../../loader.js';
import Header from '../../../header.js';
import FooterMenu from '../../../FooterMenu.js';
import Footer from '../../../footer.js';
import './../../../global.css';
import '../../breadcrumb.css';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';

import vasf from '../../../assets/vasf.jpg';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  header: {
    padding: useTheme().spacing(4.6),
    textAlign: 'center',
    color: useTheme().palette.text.secondary,
    backgroundColor: 'white',
  },
  title: {
    padding: useTheme().spacing(5),
    textAlign: 'left',
    color: useTheme().palette.text.secondary,
    backgroundColor: '#135EA7',
  },
  breadcrumb: {
    padding: useTheme().spacing(5),
    textAlign: 'right',
    color: useTheme().palette.text.secondary,
    backgroundColor: '#135EA7',
  },
  body: {
    padding: useTheme().spacing(5),
    textAlign: 'justify',
    backgroundColor: 'white',
  },
}));

function Page() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper className={classes.header} square>
            <Header />
          </Paper>
        </Grid>

        <Grid item xs={12} className="subHeader">
          <Container>
            <Row id="crumbsMenu" className="justify-content-md-start">
              <Col xs lg="5" className={classes.title} id="tituloSubHeader" sm={6}>
                {t('RedeVASF')}
              </Col>
            </Row>
          </Container>
        </Grid>

        <Grid item xs={12} style={{ backgroundColor: '#fff' }}>
          <Container>
            <Row className="justify-content-md-center">
              <Col id="texto-recursos" xs lg="10" className={classes.body}>
                <p className="infraP">
                  A Rede Metropolitana do Vale do São Francisco <span id="spotName">(RedeVASF)</span> faz parte do
                  programa Redes Comunitárias de Educação e Pesquisa (Redecomep) da RNP, abrangendo as cidades de
                  Petrolina (PE) e Juazeiro (BA). Esta tem como objetivo a interligação de instituições de ensino e
                  pesquisa com infraestrutura de redes ópticas avançadas (~ 90 km) e de alta velocidade, contribuindo
                  para o desenvolvimento, a integração e o acesso à informação entre 19 instituições da região.
                  <a href="http://redevasf.univasf.edu.br/panorama/" target="_blank">
                    <img id="mapaVasf" className="infraImg" src={vasf} alt="Mapa RePEPE" />
                  </a>
                  <p id="netRef">
                    <p className="cardItem">
                      Fontes:
                      <a
                        className="linkContact"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.rnp.br/noticias/redevasf-e-inaugurada-em-petrolina-pe"
                      >
                        <b className="linkRef"> rnp.br/noticias/redevasf-inaugurada-em-petrolina-pe</b>
                      </a>
                      <a> |</a>
                      <a
                        className="linkContact"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="http://redevasf.univasf.edu.br/panorama/"
                      >
                        <b className="linkRef"> univasf.edu.br/panorama</b>
                      </a>
                      <a> |</a>
                      <a
                        className="linkContact"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://portais.univasf.edu.br/sti/redevasf"
                      >
                        <b className="linkRef"> univasf.edu.br/sti/redevasf</b>
                      </a>
                    </p>
                  </p>
                </p>

                <br />

                <br />
                <br />
              </Col>
            </Row>
          </Container>
        </Grid>
        <Grid item xs={12} className="footer-menu-background">
          <FooterMenu />
        </Grid>

        <Grid item xs={12} className="footer-background">
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
      {/* <Loader /> */}
    </Suspense>
  );
}
