import React, { Suspense } from 'react';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';

import Statistic from '../statistics/StatisticValue';
import Loader from '../../loader.js';
import Header from '../../header.js';
import FooterMenu from '../../FooterMenu.js';
import Footer from '../../footer.js';
import Sliders from './sliders';
import Banner from './bannerP';
import QuemSomos from './quemSomos';

import './styles.css';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  header: {
    padding: useTheme().spacing(4.2),
    textAlign: 'center',
    color: useTheme().palette.text.secondary,
    backgroundColor: 'white',
  },
}));

function Page() {
  const classes = useStyles();
  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid className={classes.header} item xs={12}>
          <Header />
        </Grid>

        {/* Banner Parallax */}

        <Banner />

        {/* /Banner Parallax */}

        {/* Quem somos */}

        <QuemSomos />

        {/* /Quem somos */}

        {/* Estatisticas do pop */}

        <Statistic />

        {/* /Estatisticas do pop */}

        {/* Carrossel com clientes */}

        <Sliders />

        {/* /Carrossel com clientes */}

        {/* Menu do rodapé */}

        <FooterMenu />

        {/* /Menu do rodapé */}

        {/* Rodapé final */}

        <Footer />

        {/* /Rodapé final */}
      </Grid>
    </div>
  );
}

export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
      {/* <Loader /> */}
    </Suspense>
  );
}
