import React from 'react';
import { Statistic } from 'semantic-ui-react';
import CountUp from 'react-countup';
import Grid from '@mui/material/Grid';
import './stats.css';
import Data from './stats_data.json';

const StatisticValue = () => (
  <Grid className="statsGrid" container spacing={12} direction="row" justifyContent="center" alignItems="center" sx={{ pt: 0 }}>
    <Grid item xs={4} sm={2} className="statItem">
      <Statistic>
        <Statistic.Value text>
          <CountUp className="statNumber" end={Data.estaduais} duration={5} redraw />
        </Statistic.Value>
        <Statistic.Label>
          <p className="statTitle">Instituições Estaduais</p>
        </Statistic.Label>
      </Statistic>
    </Grid>

    <Grid item xs={4} sm={2} className="statItem">
      <Statistic>
        <Statistic.Value text>
          <CountUp className="statNumber" end={Data.federais} duration={5} redraw />
        </Statistic.Value>
        <Statistic.Label>
          <p className="statTitle">Instituições Federais</p>
        </Statistic.Label>
      </Statistic>
    </Grid>

    <Grid item xs={4} sm={2} className="statItem">
      <Statistic>
        <Statistic.Value text>
          <CountUp className="statNumber" end={Data.municipais} duration={5} />
        </Statistic.Value>
        <Statistic.Label>
          <p className="statTitle">Instituições Municipais</p>
        </Statistic.Label>
      </Statistic>
    </Grid>

    <Grid item xs={4} sm={2} className="statItem">
      <Statistic>
        <Statistic.Value text>
          <CountUp className="statNumber" end={Data.privadas} duration={5} />
        </Statistic.Value>
        <Statistic.Label>
          {' '}
          <p className="statTitle">Instituições Privadas</p>{' '}
        </Statistic.Label>
      </Statistic>
    </Grid>

    <Grid item xs={4} sm={2} className="statItem">
      <Statistic>
        <Statistic.Value text>
          <CountUp className="statNumber" end={Data.municipios} duration={5} />
        </Statistic.Value>
        <Statistic.Label>
          {' '}
          <p className="statTitle">Municípios Atendidos</p>{' '}
        </Statistic.Label>
      </Statistic>
    </Grid>

    <Grid item xs={4} sm={2} className="statItem">
      <Statistic>
        <Statistic.Value text>
          <CountUp className="statNumber" end={Data.comeps} duration={5} />
        </Statistic.Value>
        <Statistic.Label>
          {' '}
          <p className="statTitle">COMEPS</p>{' '}
        </Statistic.Label>
      </Statistic>
    </Grid>
  </Grid>
);

export default StatisticValue;
