import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles.css';
import Loader from '../../../loader.js';
import Header from '../../../header.js';
import FooterMenu from '../../../FooterMenu.js';
import Footer from '../../../footer.js';
import './../../../global.css';
import '../../breadcrumb.css';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Mapa from './mapa';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  header: {
    padding: useTheme().spacing(4.6),
    textAlign: 'center',
    color: useTheme().palette.text.secondary,
    backgroundColor: 'white',
  },
  title: {
    padding: useTheme().spacing(5),
    textAlign: 'left',
    color: useTheme().palette.text.secondary,
    backgroundColor: '#135EA7',
  },
  breadcrumb: {
    padding: useTheme().spacing(5),
    textAlign: 'right',
    color: useTheme().palette.text.secondary,
    backgroundColor: '#135EA7',
  },
  body: {
    padding: useTheme().spacing(5),
    textAlign: 'justify',
    backgroundColor: 'white',
  },
}));

function Page() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper className={classes.header} square>
            <Header />
          </Paper>
        </Grid>

        <Grid item xs={12} className="subHeader">
          <Container>
            <Row id="crumbsMenu" className="justify-content-md-start">
              <Col xs lg="5" className={classes.title} id="tituloSubHeadercomep" sm={6}>
                {t('Redecomep de Recife')}
              </Col>
            </Row>
          </Container>
        </Grid>

        <Grid item xs={12} style={{ backgroundColor: '#fff' }}>
          <Container>
            <Row className="justify-content-md-center">
              {/*  */}
              <Col id="texto-recursos" xs lg="10" className={classes.body}>
                <p className="infraP">
                  O Programa <span id="spotName">Redecomep</span> - Redes Comunitárias de Educação e Pesquisa - realiza
                  implantação de redes de alta velocidade nas regiões metropolitanas do país conectadas por Pontos de
                  Presença (PoPs) da RNP, e em cidades do interior com duas ou mais instituições federais de ensino e
                  pesquisa. Em Recife, a<span id="spotName"> Redecomep</span> está ligada ao PoP-PE e, no interior, são
                  constituídos Pontos de Agregação (PoAs) para conexão da <span id="spotName">Redecomep</span> ao PoP,
                  na capital. Na Região Metropolitana do Recife (RMR), a <span id="spotName">Redecomep</span> é
                  denominada Redecomep de Recife, com infraestrutura de aproximadamente 40 km de fibra óptica, atendendo cerca de
                  39 instituições. Esta tem como objetivo apoiar a pesquisa, o ensino e a extensão universitária,
                  fornecendo conectividade e recursos de redes avançadas para instituições de ensino e pesquisa,
                  permitindo a colaboração entre as instituições, aprimorando a troca de informações e o acesso a
                  serviços digitais, além de contribuir para o desenvolvimento da ciência e tecnologia na RMR.


                <Mapa></Mapa>

                  <p id="netRef">
                    <p className="cardItem">
                      Fontes:
                      <a
                        className="linkContact"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.rnp.br/sistema-rnp/redecomep"
                      >
                        <b className="linkRef"> rnp.br/sistema-rnp/redecomep</b>
                      </a>
                      <a> |</a>
                      <a
                        className="linkContact"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.rnp.br/noticias/iniciativa-redecomep-celebra-dez-anos-com-redes-de-alta-velocidade-por-todo-o-pais"
                      >
                        <b className="linkRef"> rnp.br/noticias/iniciativa-redecomep-celebra-dez-anos</b>
                      </a>
                    </p>
                  </p>
                </p>
                <br />
                <br />
              </Col>
            </Row>
          </Container>
        </Grid>

        <Grid item xs={12} className="footer-menu-background">
          <FooterMenu />
        </Grid>

        <Grid item xs={12} className="footer-background">
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
      {/* <Loader /> */}
    </Suspense>
  );
}
