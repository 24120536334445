import React, { Suspense } from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import Container from '@mui/material/Container';
import Box from '@mui/material/Box';

import Loader from './loader.js';
import './FooterMenu.css';

function Copyright() {
  return (
    <Container>
      <Typography id="c" align="center">
        {'Copyright © '}
        <Link style={{textDecoration: 'none'}} className="linksmenufooterc"  href="https://www.pop-pe.rnp.br/">
          PoP-PE / RNP
        </Link>{' '}
        {new Date().getFullYear()}
        {'.'}
      </Typography>
    </Container>
  );
}

const footers = [
  {
    title: 'PoP-PE',
    description: ['Início'],
    url: ['/#/'],
  },
  {
    title: 'Sobre',
    description: ['Equipe Técnica', 'Clientes'],
    url: [ '/#/Equipe', '/#/clientes'],
  },
  {
    title: 'Redes',
    description: ['Redecomep de Recife', 'RedeVASF', 'RePEPE', 'Veredas', 'IX.br'],
    url: ['/#/Redecomep_Recife', '/#/redevasf', '/#/REPEPE', '/#/veredas', '/#/IXbr'],
  },
  {
    title: 'Fale conosco',
    description: ['Contato'],
    url: ['/#/contact'],
  },
];

function scrollToTop() {
  window.scrollTo({
    top: 0,
    behavior: 'smooth', // You can change this to "auto" for instant scrolling
  });
}

function Footer() {
  return (
    <React.Fragment>
      <div className="backFooter">
        <Container maxWidth="md" component="footer" className="Footercontainer">
          <Grid container item xs={12} spacing={4} justify="space-evenly">
            {footers.map((footer) => (
              <Grid item xs={6} sm={3} key={footer.title}>
                <Typography component={'span'} variant="body1" color="primary" gutterBottom fontWeight="Regular">
                  <Box className="tittleFooter">{footer.title}</Box>
                </Typography>
                <ul className="ulFooter">
                  {footer.description.map((item, index) => (
                    <li key={item}>
                      <Link onClick={scrollToTop} style={{textDecoration: 'none'}} className="linksmenufooter" href={footer.url[index]}>
                        <Typography
                          variant="subtitle1"
                          gutterBottom
                          color="textSecondary"
                          fontWeight="fontWeightRegular"
                        >
                          <Box className="itens">{item}</Box>
                        </Typography>
                      </Link>
                    </li>
                  ))}
                </ul>
              </Grid>
            ))}
          </Grid>
          <Box mt={5}>
            <Copyright />
          </Box>
        </Container>
      </div>
    </React.Fragment>
  );
}

export default function FooterMenu() {
  return (
    <Suspense fallback={<Loader />}>
      <Footer />
      {/* <Loader /> */}
    </Suspense>
  );
}
