import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Container, Row, Col } from 'react-bootstrap';
import Iframe from 'react-iframe';
import Loader from '../../loader.js';
import Header from '../../header.js';
import FooterMenu from '../../FooterMenu.js';
import Footer from '../../footer.js';
import { FiMail, FiMapPin, FiPhone, FiServer } from 'react-icons/fi';
import { MdOutlineSupportAgent, MdNetworkCheck } from 'react-icons/md';
import { ImEarth } from 'react-icons/im';

import './styles.css';
import './../breadcrumb.css';

import 'bootstrap/dist/css/bootstrap.min.css';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 2,
  },
  header: {
    padding: useTheme().spacing(4.6),
    textAlign: 'center',
    color: useTheme().palette.text.secondary,
    backgroundColor: 'white',
  },
  title: {
    padding: useTheme().spacing(5),
    textAlign: 'left',
    color: useTheme().palette.text.secondary,
    backgroundColor: '#000A8C',
  },
  breadcrumb: {
    padding: useTheme().spacing(5),
    textAlign: 'right',
    color: useTheme().palette.text.secondary,
    backgroundColor: '#000A8C',
  },
  body: {
    padding: useTheme().spacing(5),
    textAlign: 'left',
    color: useTheme().palette.text.secondary,
    backgroundColor: 'white',
  },
}));

function Page() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <Grid id="rowtexto" container spacing={0}>
      <Grid item xs={12}>
        <Paper className={classes.header} square>
          <Header />
        </Paper>
      </Grid>

      <Grid item xs={12} className='subHeader'>
        <Container>
          <Row className="justify-content-md-start">
            <Col xs lg="5" className={classes.title} id="tituloSubHeader" sm={8}>
              {t('contact.title')}
            </Col>
          </Row>
        </Container>
      </Grid>

      <div className="contactContainer">
        <Row id="cardCenter" className="justify-content-center">
          <Col md={6} xs={9} xl={9}>
            <Row className="contactCard">
              <Col item xs={12} xl={5} className="contactItens">
                <p id="cardTitleMain">
                  <FiMapPin size="1.5em" className="cardIcons" id="mapPin" /> Ponto de Presença da RNP / PoP-PE
                </p>
                <p id="itepid">
                  <a className="linkContact" href="http://www.itep.br/" target="_blank" rel="noopener noreferrer">
                    {' '}
                    Instituto de Tecnologia de Pernambuco - ITEP.
                  </a>
                </p>
                <p className="contactAddress">
                  Av. Prof. Luiz Freire, nº 700, Cidade Universitária, CEP:50740-540, Recife-PE. Sala 21, Bloco B
                </p>
                <p className="cardTitle">
                  <MdOutlineSupportAgent size="1.5em" className="cardIcons" id="mapPin" /> Atendimento RNP
                </p>
                <p className="cardItem">
                  <FiPhone className="cardIcons" size="1.4em" />
                  Ligação ou Whatsapp:{' '}
                  <a className="linkContact" href="tel:0800 722 0216">
                    0800 722 0216
                  </a>
                </p>

                <p className="cardItem">
                  <FiMail className="cardIcons" size="1.4em" /> Email:{' '}
                  <a className="linkContact" href="mailto:atendimento@rnp.br">
                    atendimento@rnp.br
                  </a>
                </p>

                <p className="cardItem">
                  <MdNetworkCheck className="cardIcons" size="1.4em" />{' '}
                  <a
                    className="linkContact"
                    target="_blank"
                    rel="noopener noreferrer"
                    href="https://pe.medidor.rnp.br/"
                  >
                    <b className="linkContact">Medidor</b>
                  </a>{' '}
                  RNP
                </p>

                <p className="cardTitle">
                  <FiServer size="1.5em" className="cardIcons" id="mapPin" /> PIX do IX.BR em Pernambuco
                </p>

                <p className="cardItem">
                  <ImEarth className="cardIcons" size="1.3em" /> Website:
                  <a className="linkContact" target="_blank" rel="noopener noreferrer" href="https://ix.br/trafego/agregado/pe">
                    <b className="linkContact"> IX.BR</b>
                  </a>{' '}
                </p>

                <p className="cardItem">
                  <FiMail className="cardIcons" size="1.4em" /> Email de atendimento:{' '}
                  <a className="linkContact" href="mailto:pe.ix@rnp.br">
                    pe.ix@rnp.br
                  </a>
                </p>
              </Col>

              <Col item xs={12} xl={5} className="contactItensMap">
                <Iframe
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3950.415929237644!2d-34.955537285220146!3d-8.058986394197275!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x7ab1bb69e138c63%3A0x9d6aab26876f0691!2sPoP-PE%2FRNP!5e0!3m2!1spt-BR!2sbr!4v1650396764056!5m2!1spt-BR!2sbr"
                  width="100%"
                  minWidth="350px"
                  maxWidth="490px"
                  height="100%"
                  position="relative"
                  align="middle"
                  scrolling="no"
                  frameBorder="0"
                  aria-hidden="false"
                  tabindex="0"
                />
              </Col>
            </Row>
          </Col>
        </Row>
      </div>

      <Grid item xs={12} className="footer-menu-background">
        <FooterMenu />
      </Grid>

      <Grid item xs={12} className="footer-background">
        <Footer />
      </Grid>
    </Grid>
  );
}

export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
      {/* <Loader /> */}
    </Suspense>
  );
}
