import React from 'react';
import { ThemeProvider, createTheme } from '@mui/material/styles';

import './global.css';

import Routesr from './routesr';

const theme = createTheme();

function App() {
  return (
    <ThemeProvider theme={theme}>
      <Routesr />
    </ThemeProvider>
  );
}

export default App;
