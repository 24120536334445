import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Container, Row, Col } from 'react-bootstrap';
import '../styles.css';
import Loader from '../../../loader.js';
import Header from '../../../header.js';
import FooterMenu from '../../../FooterMenu.js';
import Footer from '../../../footer.js';
import './../../../global.css';
import '../../breadcrumb.css';
import '../../../../node_modules/bootstrap/dist/css/bootstrap.min.css';
import Mapa from './mapa';

function handleClick(event) {
  event.preventDefault();
  console.info('You clicked a breadcrumb.');
}

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 1,
  },
  header: {
    padding: useTheme().spacing(4.6),
    textAlign: 'center',
    color: useTheme().palette.text.secondary,
    backgroundColor: 'white',
  },
  title: {
    padding: useTheme().spacing(5),
    textAlign: 'left',
    color: useTheme().palette.text.secondary,
    backgroundColor: '#135EA7',
  },
  breadcrumb: {
    padding: useTheme().spacing(5),
    textAlign: 'right',
    color: useTheme().palette.text.secondary,
    backgroundColor: '#135EA7',
  },
  body: {
    padding: useTheme().spacing(5),
    textAlign: 'justify',
    backgroundColor: 'white',
  },
}));

function Page() {
  const classes = useStyles();
  const { t } = useTranslation();
  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper className={classes.header} square>
            <Header />
          </Paper>
        </Grid>

        <Grid item xs={12} className="subHeader">
          <Container>
            <Row id="crumbsMenu" className="justify-content-md-start">
              <Col xs lg="5" className={classes.title} id="tituloSubHeader" sm={6}>
                {t('REPEPE')}
              </Col>
            </Row>
          </Container>
        </Grid>

        <Grid item xs={12} style={{ backgroundColor: '#fff' }}>
          <Container>
            <Row className="justify-content-md-center">
              <Col id="texto-recursos" xs lg="10" className={classes.body}>
                <p className="infraP">
                  A <span id="spotName">REPEPE</span> é uma infraestrutura de comunicação óptica de alta performance –
                  com potencial de velocidade de 10Gbps (em produção), 100Gbps e seus múltiplos - que conecta
                  instituições de educação ou pesquisa, públicas ou privadas, podendo contemplar demais órgãos ou
                  entidades do Poder Executivo Estadual e dos demais Poderes. A rede foi concebida através de Parcerias
                  Público-Privadas (PPPs), tendo como estratégia de implantação o compartilhamento de infraestrutura
                  entre a Rede Nacional de Ensino e Pesquisa (RNP), Secretaria de Ciência, Tecnologia e Inovação de
                  Pernambuco (SECTI-PE), operadoras de telecomunicações do setor privado e a Neoenergia Pernambuco
                  (Celpe) com o objetivo de fomentar a sustentabilidade da rede. Público alvo da{' '}
                  <span id="spotName">RePEPE</span>:
                  <ul id="listsN">
                    <li id="listsL">Centros Tecnológicos (CTs).</li>
                    <li id="listsL">Parques Tecnológicos.</li>
                    <li id="listsL">Museus, Espaço Ciência e Observatórios.</li>
                    <li id="listsL">Campi e Hospitais da UPE.</li>
                    <li id="listsL">Armazém da Criatividade.</li>
                    <li id="listsL">Hospitais de ensino.</li>
                    <li id="listsL">Rede Estadual de Educação.</li>
                    <li id="listsL">Autarquias Municipais.</li>
                    <li id="listsL">Bibliotecas Públicas.</li>
                    <li id="listsL">Arquivos Públicos.</li>
                    <li id="listsL">Centros de Pesquisa: e.g., IPA, Hemope, IC.</li>
                  </ul>

                <Mapa></Mapa>
                  

                  <p id="netRef">
                    <p>
                      Fontes:
                      <a
                        className="linkContact"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.secti.pe.gov.br/portfolio/projetos-e-iniciativas/repepe/"
                      >
                        <b className="linkRef"> secti.pe.gov.br/portfolio/projetos-e-iniciativas/repepe</b>
                      </a>
                      <a> |</a>
                      <a
                        className="linkContact"
                        target="_blank"
                        rel="noopener noreferrer"
                        href="https://www.rnp.br/noticias/pernambuco-lanca-rede-estadual-de-educacao-e-pesquisa-em-parceria-com-rnp
                      "
                      >
                        <b className="linkRef">
                          {' '}
                          rnp.br/noticias/pernambuco-lanca-rede-estadual
                        </b>
                      </a>

                    </p>
                  </p>
                </p>

                <br />

                <br />
                <br />
              </Col>
            </Row>
          </Container>
        </Grid>
        <Grid item xs={12} className="footer-menu-background">
          <FooterMenu />
        </Grid>

        <Grid item xs={12} className="footer-background">
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
      {/* <Loader /> */}
    </Suspense>
  );
}
