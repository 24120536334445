import React, { Suspense } from 'react';
import { useTranslation } from 'react-i18next';
import Grid from '@mui/material/Grid';
import { makeStyles } from '@mui/styles';
import { useTheme } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { Container, Row, Col } from 'react-bootstrap';
import Loader from '../../loader.js';
import Header from '../../header.js';
import FooterMenu from '../../FooterMenu.js';
import Footer from '../../footer.js';
import './styles.css';
import './../breadcrumb.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import ifpe from './logos/1200px-Ifpe_logomarca.png';
import apac from './logos/APAC.png';
import cisam from './logos/cisam.png';
import facepe from './logos/facepe_logo.png';
import ifsertao from './logos/IFsertao.png';
import ipa from './logos/IPA_logo.png';
import fundaj from './logos/logo_fundaj_2012.png';
import procape from './logos/procape.png';
import secti from './logos/secti_logo.png';
import ses from './logos/ses-pe.png';
import ufpe from './logos/UFPE_logo.png';
import ufrpe from './logos/ufrpe-logao.png';
import univasf from './logos/UNIVASF.png';
import upe from './logos/UPE logo.png';
import chesf from './logos/chesf.png';
import hcp from './logos/hcp.png';
import cmr from './logos/cmr.png';
import cpor from './logos/cpor.png';
import imip from './logos/imip.png';
import ipem from './logos/ipem.png';
import itep from './logos/itep.png';
import ons from './logos/ons.png';

const useStyles = makeStyles(() => ({
  root: {
    flexGrow: 2,
  },
  header: {
    padding: useTheme().spacing(4.6),
    textAlign: 'center',
    color: useTheme().palette.text.secondary,
    backgroundColor: 'white',
  },
  title: {
    padding: useTheme().spacing(5),
    textAlign: 'left',
    color: useTheme().palette.text.secondary,
    backgroundColor: '#000A8C',
  },
  breadcrumb: {
    padding: useTheme().spacing(5),
    textAlign: 'right',
    color: useTheme().palette.text.secondary,
    backgroundColor: '#000A8C',
  },
  body: {
    padding: useTheme().spacing(5),
    textAlign: 'left',
    color: useTheme().palette.text.secondary,
    backgroundColor: 'white',
  },
}));

function Page() {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <div className={classes.root}>
      <Grid container spacing={0}>
        <Grid item xs={12}>
          <Paper className={classes.header} square>
            <Header />
          </Paper>
        </Grid>

        <Grid className='subHeader' item xs={12}>
          <Container>
            <Row className="justify-content-md-start">
              <Col xs lg="5" className={classes.title} id="tituloSubHeader" sm={6}>
                {t('Clientes')}
              </Col>
            </Row>
          </Container>
        </Grid>

        <Grid item xs={12} style={{ backgroundColor: 'white' }}>
          <Container className="customerContainerPg" style={{ marginTop: '40px', marginBottom: '40px' }}>
            <Row
              style={{
                justifyContent: 'center',
                margin: '5px',
                width: 'auto',
                display: 'flex',
              }}
            >
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.ifpe.edu.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="ifpe"
                      className="img-responsive"
                      alt="ifpe"
                      src={ifpe}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                        position: 'relative',
                        top: '-35px',
                        right: '40px',
                      }}
                    />
                  </a>
                </div>
              </Col>
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.apac.pe.gov.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="apac"
                      className="img-responsive"
                      alt="apac"
                      src={apac}
                      style={{
                        maxWidth: '110%',
                        height: 'auto',
                        position: 'relative',
                        top: '-43px',
                        right: '46px',
                      }}
                    />
                  </a>
                </div>
              </Col>
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.upe.br/uh-cisam.html" target="_blank" rel="noopener noreferrer">
                    <img
                      id="cisam"
                      className="img-responsive"
                      alt="cisam"
                      src={cisam}
                      style={{
                        width: '210px',
                        maxWidth: '95%',
                        height: 'auto',
                        marginLeft: '12px',
                        marginTop: '7px',
                      }}
                    />
                  </a>
                </div>
              </Col>
            </Row>

            <Row
              style={{
                justifyContent: 'center',
                margin: '5px',
                width: 'auto',
                display: 'flex',
              }}
            >
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.facepe.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="facepe"
                      className="img-responsive"
                      alt="facepe"
                      src={facepe}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                        position: 'relative',
                        top: '-33px',
                        right: '45px',
                      }}
                    />
                  </a>
                </div>
              </Col>
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.ifsertao-pe.edu.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="ifsertao"
                      className="img-responsive"
                      alt="ifsertao"
                      src={ifsertao}
                      style={{
                        maxWidth: '105%',
                        height: 'auto',
                        position: 'relative',
                        top: '-30px',
                        right: '48px',
                      }}
                    />
                  </a>
                </div>
              </Col>
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.ufpe.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="ufpe"
                      className="img-responsive"
                      alt="ufpe"
                      src={ufpe}
                      style={{
                        background: 'darkred',
                        width: '88%',
                        borderRadius: '4px',
                        marginLeft: '17px',
                        height: 'auto',
                        marginTop: '5px',
                      }}
                    />
                  </a>
                </div>
              </Col>
            </Row>

            <Row
              style={{
                justifyContent: 'center',
                margin: '5px',
                width: 'auto',
                display: 'flex',
              }}
            >
              <Col className="boxClients" md={3} style={{ maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.fundaj.gov.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="fundaj"
                      className="img-responsive"
                      alt="fundaj"
                      src={fundaj}
                      style={{
                        maxWidth: '108%',
                        height: 'auto',
                        position: 'relative',
                        top: '-14px',
                        right: '52px',
                      }}
                    />
                  </a>
                </div>
              </Col>
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.huprocape.pe.gov.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="procap"
                      className="img-responsive"
                      alt="procape"
                      src={procape}
                      style={{
                        maxWidth: '105%',
                        height: 'auto',
                        position: 'relative',
                        top: '-25px',
                        right: '50px',
                      }}
                    />
                  </a>
                </div>
              </Col>
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.secti.pe.gov.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="secti"
                      className="img-responsive"
                      alt="secti"
                      src={secti}
                      style={{
                        maxWidth: '112%',
                        height: 'auto',
                        position: 'relative',
                        top: '-15px',
                        right: '55px',
                      }}
                    />
                  </a>
                </div>
              </Col>
            </Row>

            <Row
              style={{
                justifyContent: 'center',
                margin: '5px',
                width: 'auto',
                display: 'flex',
              }}
            >
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.saude.pe.gov.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="ses"
                      className="img-responsive"
                      alt="ses"
                      src={ses}
                      style={{
                        maxWidth: '80%',
                        height: 'auto',
                        position: 'relative',
                        top: '-56px',
                        right: '30px',
                      }}
                    />
                  </a>
                </div>
              </Col>
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.ipa.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="ipa"
                      className="img-responsive"
                      alt="ipa"
                      src={ipa}
                      style={{
                        maxWidth: '60%',
                        height: 'auto',
                        position: 'relative',
                        top: '-50px',
                        right: '0px',
                      }}
                    />
                  </a>
                </div>
              </Col>
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.ufrpe.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="ufrpe"
                      className="img-responsive"
                      alt="ufrpe"
                      src={ufrpe}
                      style={{
                        maxWidth: '45%',
                        height: 'auto',
                        position: 'relative',
                        top: '-47px',
                        right: '-16px',
                      }}
                    />
                  </a>
                </div>
              </Col>
            </Row>

            <Row
              style={{
                justifyContent: 'center',
                margin: '5px',
                width: 'auto',
                display: 'flex',
              }}
            >
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.upe.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="upe"
                      className="img-responsive"
                      alt="upe"
                      src={upe}
                      style={{
                        maxWidth: '90%',
                        height: 'auto',
                        position: 'relative',
                        top: '-48px',
                        right: '35px',
                      }}
                    />
                  </a>
                </div>
              </Col>
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://portais.univasf.edu.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="univasf"
                      className="img-responsive"
                      alt="univasf"
                      src={univasf}
                      style={{
                        maxWidth: '95%',
                        height: 'auto',
                        position: 'relative',
                        top: '-50px',
                        right: '40px',
                      }}
                    />
                  </a>
                </div>
              </Col>
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.chesf.com.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="chesf"
                      className="img-responsive"
                      alt="chesf"
                      src={chesf}
                      style={{
                        maxWidth: '100%',
                        height: 'auto',
                        position: 'relative',
                        top: '-40px',
                        right: '49px',
                      }}
                    />
                  </a>
                </div>
              </Col>
            </Row>

            <Row
              style={{
                justifyContent: 'center',
                margin: '5px',
                width: 'auto',
                display: 'flex',
              }}
            >
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.hcp.org.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="hcp"
                      className="img-responsive"
                      alt="hcp"
                      src={hcp}
                      style={{
                        maxWidth: '125%',
                        height: 'auto',
                        position: 'relative',
                        top: '-42px',
                        right: '32px',
                      }}
                    />
                  </a>
                </div>
              </Col>
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.cmr.eb.mil.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="cmr"
                      className="img-responsive"
                      alt="cmr"
                      src={cmr}
                      style={{
                        maxWidth: '45%',
                        height: 'auto',
                        position: 'relative',
                        top: '-45px',
                        right: '-15px',
                      }}
                    />
                  </a>
                </div>
              </Col>
              <Col
                className="boxClients"
                md={3}
                style={{
                  margin: '1px',
                  maxWidth: '280px',
                  justifyItems: 'center',
                }}
              >
                <div className="imgBoxC">
                  <a href="https://www.cporr.eb.mil.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="cpor"
                      className="img-responsive"
                      alt="cpor"
                      src={cpor}
                      style={{
                        maxWidth: '50%',
                        height: 'auto',
                        position: 'relative',
                        top: '-45px',
                        right: '-15px',
                      }}
                    />
                  </a>
                </div>
              </Col>
            </Row>

            <Row
              style={{
                justifyContent: 'center',
                margin: '5px',
                width: 'auto',
                display: 'flex',
              }}
            >
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.ipem.pe.gov.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="ipem"
                      className="img-responsive"
                      alt="ipem"
                      src={ipem}
                      style={{
                        maxWidth: '95%',
                        height: 'auto',
                        position: 'relative',
                        top: '-40px',
                        right: '35px',
                      }}
                    />
                  </a>
                </div>
              </Col>
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www1.imip.org.br/imip/home/index.html" target="_blank" rel="noopener noreferrer">
                    <img
                      id="imip"
                      className="img-responsive"
                      alt="imip"
                      src={imip}
                      style={{
                        background: 'darkgreen',
                        maxWidth: '98%',
                        height: 'auto',
                        position: 'relative',
                        top: '-20px',
                        right: '35px',
                        borderRadius: '5px',
                      }}
                    />
                  </a>
                </div>
              </Col>
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.itep.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="itep"
                      className="img-responsive"
                      alt="itep"
                      src={itep}
                      style={{
                        maxWidth: '130%',
                        height: 'auto',
                        position: 'relative',
                        top: '-45px',
                        right: '55px',
                      }}
                    />
                  </a>
                </div>
              </Col>
              <Col className="boxClients" md={3} style={{ margin: '1px', maxWidth: '280px' }}>
                <div className="imgBoxC">
                  <a href="https://www.ons.org.br/" target="_blank" rel="noopener noreferrer">
                    <img
                      id="ons"
                      className="img-responsive"
                      alt="ons"
                      src={ons}
                      style={{
                        maxWidth: '114%',
                        height: 'auto',
                        position: 'relative',
                        top: '-32px',
                        right: '61px',
                        borderRadius: '195px',
                      }}
                    />
                  </a>
                </div>
              </Col>
            </Row>
          </Container>
        </Grid>

        <Grid item xs={12} className="footer-menu-background">
          <FooterMenu />
        </Grid>

        <Grid item xs={12} className="footer-background">
          <Footer />
        </Grid>
      </Grid>
    </div>
  );
}

export default function App() {
  return (
    <Suspense fallback={<Loader />}>
      <Page />
      {/* <Loader /> */}
    </Suspense>
  );
}
