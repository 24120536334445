import React from 'react';
import { Parallax } from 'react-parallax';
import imagem from '../../assets/recife.jpg';
import Grid from '@mui/material/Grid';
import './styles.css';

function Banner() {
  return (
    <Grid item xs={12}>
      <Parallax bgImage={imagem} strength={-200}>
        <div className="recifeImage">
          <div className="slogam">
            <p>Vamos além da conectividade</p> <br />
            <p className="slogam2">
              desde 1990 promovendo alta velocidade e qualidade às Instituições de Ensino e Pesquisa do Estado!
            </p>
          </div>
        </div>
      </Parallax>
    </Grid>
  );
}

export default Banner;
